import { CSSProperties, ReactElement, ReactNode, Ref, forwardRef } from 'react';
import Image from 'next/image';
import clsx from 'clsx';
type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: (children: ReactNode) => ReactElement | null;
  children: ReactNode;
};
const ConditionalWrapper = ({
  condition,
  wrapper,
  children
}: ConditionalWrapperProps) => condition ? wrapper(children) : <div className="p-[3px] h-full bg-gray-200">{children}</div>;
type Props = {
  className?: string;
  gradientBorder: boolean;
  gradientBadgeText?: string;
  imageSrc?: {
    url: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
  imageAlt?: string;
  imageWidth?: number;
  text?: ReactNode;
};
export const GradientBorderCard = forwardRef(function GradientBorderCard({
  className,
  text,
  gradientBorder = false,
  gradientBadgeText,
  imageSrc,
  imageAlt,
  imageWidth = 50
}: Props, ref: Ref<HTMLDivElement>) {
  return <div ref={ref} className={clsx(className, 'self-stretch')}>
			<ConditionalWrapper condition={gradientBorder} wrapper={children => <div style={({
      '--after-content': `"${gradientBadgeText}"`
    } as CSSProperties)} className={`p-[3px] relative bg-gradient-to-tr from-bc-blue from-[-35%] to-bc-green to-85% h-full
            before:absolute before:bg-gradient-to-tr before:from-transparent before:to-bc-green before:from-50% before:to-50% before:w-[70px] before:h-[70px] before:top-[1px] before:right-0
            after:content-[var(--after-content)] after:absolute after:right-[5px] after:top-3 after:text-xxs after:font-bold after:text-bc-black
            `}>
						{children}
					</div>}>
				<div className="flex flex-col gap-8 py-10 px-12 text-white h-full bg-gray-200">
					<div className="h-14">
						{imageSrc && <Image src={imageSrc.url} alt={imageAlt ?? ''} height={imageWidth / (imageSrc.dimensions.width / imageSrc.dimensions.height)} width={imageWidth} />}
					</div>
					{text}
				</div>
			</ConditionalWrapper>
		</div>;
});